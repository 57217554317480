import { Title } from 'components/ui/title/Title';
import styles from './detail.module.scss';
import { CardDetail } from 'components/ui/cardDetail/CardDetail';
import { XFilled } from '@ant-design/icons';

const FinancialCreditScoringAssistantDetailPage = () => {
    const challenges =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Long and inefficient credit scoring process
            </p>
            <p>
                <XFilled /> Lack of transparency to the customer
            </p>
            <p>
                <XFilled /> No forecasting visibility to the customer
            </p>
        </div>
    const solution =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Uses predictive analytics to evaluate customer creditworthiness and warns about future risk factors.
            </p>
            <p>
                <XFilled /> Analyzes large amounts of customer and market data (Credit history, spending patterns, income fluctuations, market trends, industry trends, demographic data)
            </p>
            <p>
                <XFilled /> Generates personalized credit schemes and personalized alerts
            </p>
            <p>
                <XFilled /> Integrated with social media, news, and third-party trust providers
            </p>
        </div>
    const value =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Increases trust and CSAT through transparent and demystified credit scoring process
            </p>
            <p>
                <XFilled /> Improves credit decision making, reducing the risk of defaults and improving the overall quality of the loan portfolio.
            </p>
            <p>
                <XFilled /> Increase the operational efficiency, reducing the time and resources required for manual underwriting.
            </p>
        </div>
    return (
        <div className={styles.detail}>
            <Title value='Financial Credit Scoring Assistant' />
            <CardDetail
                title={'Challenges'}
                content={challenges}
            />
            <CardDetail
                title={'Solution: NessGen Credit Scoring Assistant'}
                content={solution}
            />
            <CardDetail
                title={'Business Value'}
                content={value}
            />
        </div>
    )
}

export { FinancialCreditScoringAssistantDetailPage };
