import { LineChart as RechartLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessagehartDataSetType, MessageChartDataType } from "lib/interfaces/message";
import { getChartData, getChartDatasetColor } from 'lib/utils/chart';


type LineChartProps = {
    chartData: MessageChartDataType;
}

const LineChart = ({ chartData }: LineChartProps) => {
    const data = getChartData(chartData);

    return (
        <ResponsiveContainer width="100%" height="100%" >
            <RechartLineChart
                width={550}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {chartData.datasets.map((dataset: MessagehartDataSetType, index: number) => (
                    <Line type="monotone" dataKey={dataset.label} stroke={getChartDatasetColor(index)} activeDot={{ r: 8 }} />
                ))}
            </RechartLineChart>
        </ResponsiveContainer>
    );
}

export default LineChart;