import { Layout } from 'antd';
import 'i18n/config';
import styles from './PageLayout.module.scss'
import { PageHeader } from './PageHeader';
import { PageFooter } from './PageFooter';


type PageLayoutProps = {
  children?: JSX.Element | JSX.Element[];
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <Layout>
      <PageHeader />
      <Layout
        className={styles.pageWrapper}>
        <Layout className={styles.contentWrapper}>
          {children}
        </Layout>
      </Layout>
      <PageFooter />
    </Layout>
  )
}

export { PageLayout };
