import { BroadcastEventType, AuthState } from 'lib/interfaces/auth';
import { ROUTES } from 'lib/routes';
import { broadcastAuthState } from 'lib/utils/auth.utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authInitialState, authEmptyState } from 'services/auth';
import { useLocalStorage } from 'usehooks-ts';

export const useAuthData = () => {
    const [authData, setAuthData] = useState(authInitialState);
    const [, setAuthDataLocal] = useLocalStorage('authDataLocal', authInitialState);
    const navigate = useNavigate();

    const readChannel = useMemo(() => new BroadcastChannel('authData'), []);
    readChannel.onmessage = (event) => {
        const eventType: BroadcastEventType = event.data.eventType;

        if (JSON.stringify(event.data.data) !== JSON.stringify(authData)) {
            setAuthData(event.data.data);
        }

        if (eventType === 'logout') {
            navigate(ROUTES.landingPage);
        }
    };

    useEffect(() => {
        return () => {
            readChannel.close();
        };
    }, [readChannel]);

    const storeData = useCallback((newAuthData: Partial<AuthState>) => {
        const mergedAuthData = { ...authData, ...newAuthData };
        setAuthData(mergedAuthData);
        setAuthDataLocal(mergedAuthData);
        broadcastAuthState('authData', 'update', mergedAuthData);
    }, [authData, setAuthDataLocal]);

    const logout = useCallback(() => {
        setAuthData(authEmptyState);
        setAuthDataLocal(authEmptyState);
        broadcastAuthState('authData', 'logout', authEmptyState);
    }, [setAuthDataLocal]);

    const { accessToken, isLogged, idToken, user_name, user_email, clientId, authError } = authData;

    return useMemo(() => ({
        accessToken,
        isLogged,
        idToken,
        user_name,
        user_email,
        clientId,
        authError,
        storeData,
        logout
    }), [accessToken, isLogged, idToken, user_name, user_email, clientId, authError, storeData, logout]);
};
