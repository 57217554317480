import { Title } from 'components/ui/title/Title';
import styles from './detail.module.scss';
import { CardDetail } from 'components/ui/cardDetail/CardDetail';
import { XFilled } from '@ant-design/icons';

const CustomerServiceAICompanionDetailPage = () => {
    const challenges =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Hard to find the right information
            </p>
            <p>
                <XFilled /> Long ticket resolution times
            </p>
            <p>
                <XFilled /> Support engineers relying on knowledge transfer, memorizing technical details, and on manual search
            </p>
        </div>
    const solution =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Capable of human-like conversation
            </p>
            <p>
                <XFilled /> Information retrieval of entire customer knowledge base (product documentation, historical tickets, customer data)
            </p>
            <p>
                <XFilled /> Processes structured, semi-structured and unstructured data (plain text, tables, Excel spreadsheets, diagrams, PDFs, Webpages, SQL data)
            </p>
        </div>
    const value =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Empowers agents to solve higher amount of customer support tickets
            </p>
            <p>
                <XFilled /> Reduces operations costs by automating ticket handling, streamlining communications, and optimizing agent time
            </p>
        </div>
    return (
        <div className={styles.detail}>
            <Title value='Customer Service AI Companion' />
            <CardDetail
                title={'Challenges'}
                content={challenges}
            />
            <CardDetail
                title={'Solution: NessGen Customer Service Assistant'}
                content={solution}
            />
            <CardDetail
                title={'Business Value'}
                content={value}
            />
        </div>
    )
}

export { CustomerServiceAICompanionDetailPage };
