export enum ChartType {
    GROUPED_BAR = "grouped_bar",
    STACK_AREA = "stack_area",
    LINE = "line",
}

export enum ContentType {
    TEXT = 'text',
    CHART = 'chart',
    TABLE = 'table',
}

export enum AuthorType {
    USER = 'user',
    BOT = 'bot',
}