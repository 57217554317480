import { Title } from 'components/ui/title/Title';
import styles from './detail.module.scss';
import { CardDetail } from 'components/ui/cardDetail/CardDetail';
import { XFilled } from '@ant-design/icons';

const PredictiveMaintenanceSystemDetailPage = () => {
    const challenges =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Hard to find the right information
            </p>
            <p>
                <XFilled /> Long incident resolution times
            </p>
            <p>
                <XFilled /> Operations team relying on manual processes for incident reporting and resolution
            </p>
        </div>
    const solution =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Our AI Incident Management System cuts incident response times from hours to minutes through predictive issue identification.
            </p>
            <p>
                <XFilled /> Conversational interface connected to all IoT monitoring systems (oil level & temperature, brakes, hydraulic pressure, etc.)
            </p>
            <p>
                <XFilled /> Integrates and orchestrates multiple customer databases and LLM
            </p>
            <p>
                <XFilled /> Identifies anomalies, their root causes, and provides solutions
            </p>
        </div>
    const value =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Revolutionizes fleet management by streamlining and integrating all activities into a comprehensive Copilot interface
            </p>
            <p>
                <XFilled /> Reduces resolution time
            </p>
            <p>
                <XFilled /> Introduces predictive incident management
            </p>
        </div>
    return (
        <div className={styles.detail}>
            <Title value='Predictive Maintenance System' />
            <CardDetail
                title={'Challenges'}
                content={challenges}
            />
            <CardDetail
                title={'Solution: NessGen Incident Management Copilot'}
                content={solution}
            />
            <CardDetail
                title={'Business Value'}
                content={value}
            />
        </div>
    )
}

export { PredictiveMaintenanceSystemDetailPage };
