import { BarChart as RechartBarChart, Bar, XAxis, YAxis, Rectangle, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessagehartDataSetType, MessageChartDataType } from "lib/interfaces/message";
import { getChartData, getChartDatasetColor, getChartDatasetFillColor } from 'lib/utils/chart';

type BarChartProps = {
    chartData: MessageChartDataType;
}

const BarChart = ({ chartData }: BarChartProps) => {
    const data = getChartData(chartData);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RechartBarChart
                width={550}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {chartData.datasets.map((dataset: MessagehartDataSetType, index: number) => {
                    const fillColor: string = getChartDatasetFillColor(index);
                    return (
                        <Bar
                            dataKey={dataset.label}
                            fill={getChartDatasetColor(index)}
                            activeBar={<Rectangle fill={fillColor} stroke={fillColor} />}
                        />
                    )
                })}
            </RechartBarChart>
        </ResponsiveContainer>
    );
}

export default BarChart;