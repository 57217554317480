import { Layout } from 'antd';
import 'i18n/config';
import styles from './LandingPageLayout.module.scss'
import { LandingHeader } from './LandingHeader';

type LandingPageLayoutProps = {
    children?: JSX.Element | JSX.Element[];
}

const LandingPageLayout = ({ children }: LandingPageLayoutProps) => {

    return (
        <Layout>
            <LandingHeader />
            <Layout
                className={styles.pageWrapper}>
                <Layout className={styles.contentWrapper}>
                    {children}
                </Layout>
                <div className={styles.backgroundWrapper} />
            </Layout>
        </Layout>
    )
}

export { LandingPageLayout };
