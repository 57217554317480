import styles from './UserMessage.module.scss';
import { Message } from 'lib/interfaces/message';
import { Avatar, List } from 'antd';
import { UserOutlined } from "@ant-design/icons";


type UserMessageProps = {
    message: Message
}

const UserMessage = ({ message }: UserMessageProps) => {
    return (
        <div
            className={styles.userMessage}>
            <List.Item.Meta
                avatar={<Avatar src={<UserOutlined />} />}
                className={styles.userMessage_meta}
            />
            {
                'text' in message.message[0].content &&
                <div className={styles.userMessage_content}>
                    {message.message[0].content.text}
                </div>

            }
        </div>
    )

}

export default UserMessage;
