const dev = {
  CLIENT_ID: '1nemvut5nup9ho9b0rjc9jisgb',
  API_BASE_URL: 'https://dev.nessgen.net/api/v1',
  WS_URL: 'wss://dev.nessgen.net/api/v1/ws',
  AUTH_URL: 'https://login-dev.nessgen.net/'
};

// TODO: update the hostname for WS
const prod = {
  CLIENT_ID: '4n8fm3e4elr8bb438omebol7n3',
  API_BASE_URL: 'https://nessgen.net/api/v1',
  WS_URL: 'wss://nessgen.net/api/v1/ws',
  AUTH_URL: 'https://login.nessgen.net/'
};

const prodRegex = /^https:\/\/(www\.)?nessgen\.net.*$/i;
let envVariables = dev;
if (window.location.href.match(prodRegex)) {
  envVariables = prod;
}

const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  PATH_ROOT: '',
  ...envVariables
};

export default config;
