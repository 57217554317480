import { lockedMutation, tokenAboutToExpire } from 'lib/utils/auth.utils';
import { useInterval } from 'usehooks-ts';
import { useRefreshTokenMutation } from 'services/auth';
import { useAuthContext } from 'hooks/useAuthContext';
import config from 'config';
import Cognito from 'components/cognito/Cognito';

export function AppControl() {
    const { authError, accessToken, idToken, storeData } = useAuthContext();
    const refreshAuth = useRefreshTokenMutation(storeData);

    const runRefreshToken = async () => {
        if (
            refreshAuth.isPending
            || (idToken && !tokenAboutToExpire(idToken))
            || (!accessToken || accessToken === 'null')
        ) {
            return;
        }

        await lockedMutation(refreshAuth, { cognitoClientId: config.CLIENT_ID, accessToken });
    };


    useInterval(runRefreshToken, 60000);

    if (authError) {
        return <Cognito />;
    }
}