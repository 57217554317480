import { t } from 'i18next';
import { Input, List, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import config from 'config';
import useWebSocket from "react-use-websocket";
import styles from './ChartPage.module.scss';
import { useAuthContext } from "hooks/useAuthContext";
import { Message } from 'lib/interfaces/message';
import { AuthorType, ContentType } from 'lib/enums/message';
import UserMessage from 'components/ui/userMessage/UserMessage';
import BotMessage from 'components/ui/botMessage/BotMessage';
import { useUUID } from 'hooks/useUUID';

const { TextArea } = Input;

const ChatPage = () => {
  const { idToken } = useAuthContext();
  const [socketUrl, setSocketUrl] = useState(config.WS_URL);
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const {
    sendMessage,
    lastMessage,
    readyState
  } = useWebSocket(socketUrl, {
    protocols: ["token", idToken ?? ""]
  });
  // const [dataSource, setDataSource] = useState([]);
  // const [loading, setLoading] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const anchor = useRef<null | HTMLDivElement>(null);
  const selectedAccelerator = "sales-assistant"; // to be replaced with the user selected accelerator
  const loggedInUsername = "demo-user";
  const { uuid } = useUUID();

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      setMessageHistory((prev) => prev.concat(data));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (anchor !== null && anchor.current !== null) {
      anchor.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageHistory]);

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const msg = e.currentTarget.value.trim();
    e.preventDefault();

    if (msg !== '') {
      const newMessage: Message = {
        conversation_id: uuid,
        message_id: (messageHistory.length + 1).toString(),
        timestamp: new Date().toISOString(),
        message: [{
          content_type: ContentType.TEXT,
          content: {
            text: msg
          }
        }],
        accelerator: selectedAccelerator,
        username: loggedInUsername,
        author_type: AuthorType.USER
      };
      const newMessageHistory = messageHistory.concat([newMessage]);

      setTextAreaValue('');
      setMessageHistory(newMessageHistory);
      sendMessage(JSON.stringify(newMessage));
    }
  };

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  }

  return (
    <div className={styles.chat_container}>
      <Title level={2}>{t('Chat')}</Title>
      <div
        className={styles.chat_messagesWrapper}
      >
        <List
          dataSource={messageHistory}
          itemLayout="vertical"
          className={styles.chat_list}
          renderItem={(item) => (
            <List.Item key={item.message_id} className={styles.chat_list_item}>
              {item.author_type === AuthorType.USER ?
                <UserMessage message={item} /> :
                <BotMessage message={item} />
              }
            </List.Item>
          )}
        >
          <div ref={anchor} />
        </List>
      </div>
      <Space size='middle' direction='vertical' style={{ width: '100%' }}>
        <TextArea
          value={textAreaValue}
          onChange={onTextAreaChange}
          showCount
          maxLength={1000}
          style={{ height: 120, resize: 'none' }}
          onPressEnter={onPressEnter}
          placeholder='Start your conversation here'
        />
      </Space>
    </div>
  )
}

export { ChatPage };
