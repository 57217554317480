import styles from './LandingPage.module.scss';
import { Button } from 'components/ui/button/Button';
import { ROUTES } from 'lib/routes';
import { useNavigate } from 'react-router-dom';
import genAI_logo from 'assets/genAI_logo.png';


const LandingPage = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(ROUTES.homepage, { replace: true });
    };

    const handleRegisterDemo = () => {
        window.location.href = 'mailto:mihai.hosu@ness.com';
    };

    return (
        <div className={styles.landingPage}>
            <div className={styles.landingPage_content}>
                <img src={genAI_logo} alt={'genAI_logo'} />
                <h1>AI for the Future</h1>
                <h2>From discovering opportunities to scaling bespoke GenAI solutions,
                    NessGen accelerates your AI journey, empowering you to innovate
                    swiftly and strategically.
                </h2>
                <Button value={'register for demo'} handleClick={() => handleRegisterDemo()} kind={'big'} />
                <Button value={'go to showcase'} handleClick={() => handleRedirect()} kind={'big'} />
            </div>
        </div >
    )
}

export { LandingPage };
