import React from 'react';
import { Card as AntCard } from 'antd';
import styles from './CardMain.module.scss';
import { LinkButton } from 'components/ui/linkButton/LinkButton';

const { Meta } = AntCard;

type CardProps = {
    title: string;
    text: string;
    link_chat: string;
    image: React.ReactElement;
    redirectToLocalhost?: string;
}

const CardMain = ({ title, text, link_chat, image, redirectToLocalhost }: CardProps) => {
    return (
        <AntCard
            className={styles.card}
            cover={image}>
            <Meta
                title={title}
                description={text}
            />
            <div className={styles.card_actionWrapper}>
                <LinkButton value={'open chat'} link={link_chat} redirectToLocalhost={redirectToLocalhost} />
            </div>
        </AntCard>
    )
}

export { CardMain };
