import { Layout } from 'antd';
import nessLogo from 'assets/ness_logo.png';
import styles from './LandingHeader.module.scss';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'lib/routes';

const { Header } = Layout;

const LandingHeader = () => {
    return (
        <Header className={styles.header}>
            <NavLink to={ROUTES.homepage}>
                <img src={nessLogo} className={styles.header_logo} alt="nessLogo" />
            </NavLink>
        </Header>
    )

}

export { LandingHeader };
