import { Layout } from 'antd';
import styles from './PageFooter.module.scss';

const { Footer } = Layout;

const PageFooter = () => {
    return (
        <Footer className={styles.footer}>
            Ness © 2024
        </Footer>
    )

}

export { PageFooter };
