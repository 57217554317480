import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import config from 'config';
import { jwtDecode } from 'jwt-decode';
import { AuthResponseTokens, AuthState } from 'lib/interfaces/auth';
import { convertAxiosError } from 'lib/utils/auth.utils';

const localStorage = JSON.parse(window.localStorage.getItem('authDataLocal') || '{}');

export const authInitialState: AuthState = {
  isLogged: localStorage?.isLogged ?? false,
  idToken: localStorage?.idToken ?? null,
  accessToken: localStorage?.accessToken ?? null,
  user_name: localStorage?.user_name ?? undefined,
  user_email: localStorage?.user_email ?? undefined,
  clientId: localStorage?.clientId ?? undefined,
  authError: localStorage?.authError ?? undefined
};

export const authEmptyState: AuthState = {
  isLogged: false,
  idToken: null,
  accessToken: null,
  clientId: undefined,
  authError: undefined
};

export function getTargetLoginUrl (cognitoClientId: string | undefined, url: string): string {
  return `${config.AUTH_URL}login?client_id=${cognitoClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${url}/cognito`;
}

const updateToken = async ({url, cognitoClientId, code}: Partial<QueryParams>) => {
  console.log('new');
  const body = `grant_type=authorization_code&client_id=${cognitoClientId}&code=${code}&redirect_uri=${url + '/cognito'}`;
  const {data} = await axios.post<AuthResponseTokens>(`${config.API_BASE_URL}/token`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  return data;
};

const refreshToken = async ({cognitoClientId, accessToken}: Partial<QueryParams>) => {
  const body = `grant_type=refresh_token&client_id=${cognitoClientId}&access_token=${accessToken}`;
  const {data} = await axios.post<AuthResponseTokens>(`${config.API_BASE_URL}/token`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  return data;
};

const revokeToken = async ({accessToken}: Partial<QueryParams>) => {
  const cognitoClientId = config.CLIENT_ID;
  const body = `client_id=${cognitoClientId}&token=${accessToken}`;
  const {data} = await axios.post<AuthResponseTokens>(`${config.API_BASE_URL}/revoke`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  return data;
};

export type QueryParams = {
  url: string,
  cognitoClientId: string,
  code: string,
  accessToken: string,
  setAuthData: (data: Partial<AuthState>) => void
}

export const useAuthenticateMutation = (setAuthData: (data: Partial<AuthState>) => void) => {
  return useMutation({
    mutationFn: async ({url, cognitoClientId, code}: Partial<QueryParams>) => updateToken({url, cognitoClientId, code}),
    onSuccess: (data: AuthResponseTokens) => {
      setAuthData(validateTokens(data));
    },
    onError: (error: AxiosError) => {
      setAuthData({authError: convertAxiosError(error), accessToken: null, idToken: null});
    }
  });
};

export const useRevokeTokenMutation = (logout: () => void) => {
  return useMutation({
    mutationFn: async ({accessToken}: Partial<QueryParams>) => revokeToken({accessToken}),
    onSuccess: async () => {
      logout();
    },
    onError: () => {
      logout();
    }
  });
};

export const useRefreshTokenMutation = (setAuthData: (data: Partial<AuthState>) => void) => {
  return useMutation({
    mutationFn: async ({cognitoClientId, accessToken}: Partial<QueryParams>) => refreshToken({
      cognitoClientId,
      accessToken,
      setAuthData
    }),
    onSuccess: (data: AuthResponseTokens) => {
      setAuthData(validateTokens(data));
    },
    onError: (error: AxiosError) => {
      setAuthData({authError: convertAxiosError(error), accessToken: null});
    }
  });
};

export function validateTokens (data: AuthResponseTokens): AuthState {
  if (!data || !data.id_token) {
    return authEmptyState;
  }

  let idTokenDecoded;
  try {
    idTokenDecoded = jwtDecode(data.id_token) as any;
  } catch (err) {
    return authEmptyState;
  }

  const payload: AuthState = {
    idToken: data.id_token,
    accessToken: data.access_token,
    user_name: idTokenDecoded ? idTokenDecoded['cognito:username'] : undefined,
    user_email: idTokenDecoded ? idTokenDecoded.email : undefined,
    clientId: idTokenDecoded ? idTokenDecoded['custom:clientId'] : undefined,
    isLogged: true
  };

  return payload;
}
