import { Button as AntButton } from 'antd';
import styles from './Button.module.scss';

type ButtonProps = {
    value: string;
    kind?: 'normal' | 'big';
    handleClick: (e: React.MouseEvent) => void;
}

const Button = ({ value, kind, handleClick }: ButtonProps) => {
    return (
        <AntButton
            className={kind === 'big' ? styles.button_big : styles.button}
            type="primary"
            onClick={handleClick}>
            {value}
        </AntButton>
    )

}

export { Button };
