import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
    value: string;
    link: string;
    redirectToLocalhost?: string;
}

const LinkButton = ({ link, value, redirectToLocalhost }: LinkButtonProps) => {
    const navigate = useNavigate();

    if (redirectToLocalhost) {
        return (
            <Button
                className={styles.linkButton}
                type="primary"
                onClick={() => window.open(redirectToLocalhost)}>
                {value}
            </Button>
        )
    }

    return (
        <Button
            className={styles.linkButton}
            type="primary"
            onClick={() => navigate(link)}>
            {value}
        </Button>
    )

}

export { LinkButton };
