import { useAuthContext } from 'hooks/useAuthContext';
import { ROUTES } from 'lib/routes';
import { usePrevious } from 'hooks/usePrevious';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isValidToken, lockedMutation } from 'lib/utils/auth.utils';
import { getTargetLoginUrl, useAuthenticateMutation, useRefreshTokenMutation } from 'services/auth';
import config from 'config';
import { LoadingDialog } from 'components/ui/loadingDialog/LoadingDialog';
import { ErrorDialog } from 'components/ui/errorDialog/ErrorDialog';

const Cognito: React.FC<{}> = () => {
    const { isLogged, authError, accessToken, idToken, clientId, user_name, user_email, storeData, logout } = useAuthContext();
    const prevAuthData = usePrevious({ isLogged, authError, accessToken, idToken, clientId, user_name, user_email });
    const getClientId: string | undefined = config.CLIENT_ID

    const urlParams = new URLSearchParams(window.location.search);

    const code: string | null = urlParams.get('code');
    const url: string = window.location.origin;

    const newAuth = useAuthenticateMutation(storeData);
    const refreshAuth = useRefreshTokenMutation(storeData);

    useEffect(() => {
        async function startAuth() {
            if (code && (!idToken || !isValidToken(idToken))) {
                if (newAuth.isPending || newAuth.error) return;
                newAuth.mutate({ url, cognitoClientId: getClientId, code });
            } else if (accessToken) {
                if (refreshAuth.isPending) return;
                await lockedMutation(refreshAuth, { cognitoClientId: getClientId, accessToken });
            } else {
                window.location.assign(getTargetLoginUrl(getClientId, url));
            }
        }

        if (!authError && (prevAuthData.idToken !== idToken || prevAuthData.isLogged !== isLogged)) {
            startAuth();
        }
    }, [code, isLogged, authError, accessToken, idToken, prevAuthData.isLogged, prevAuthData.idToken, newAuth, url, getClientId, refreshAuth, storeData]);

    if (isLogged && !authError && idToken && isValidToken(idToken)) {
        return <Navigate to={ROUTES.homepage} />;
    }

    if (authError?.status === 400 && authError?.code === 'ERR_BAD_REQUEST') {
        return <ErrorDialog
            title={'Authentication failed'}
            message={'Refresh token is probably expired. Please login again.'}
            actionText={'Home'}
            action={() => {
                logout();
            }} />;
    }

    if (authError?.status === 403) {
        return <ErrorDialog
            title={'Authentication failed'}
            message={isValidToken(idToken ?? '') ? 'Your user is probably misconfigured. Please contact Matrix support.' : 'Authorization token expired.'}
            actionText={'Home'}
            action={() => {
                logout();
            }} />;
    }

    if (authError) {
        return <ErrorDialog
            title={'Authentication failed'}
            message={'Go back to home page and try again.'}
            actionText={'Home'}
            action={() => {
                logout();
            }} />;
    }


    return <LoadingDialog value={'Authorizing'} />;
};

export default Cognito;
