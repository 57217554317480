import styles from './Title.module.scss';

type TitleProps = {
    value: string;
}

const Title = ({ value }: TitleProps) => {
    return (
        <h1 className={styles.title}>{value}</h1>
    )
}

export { Title };
