import { ChartDataType } from "lib/interfaces/chart";
import { MessageChartDataType, MessagehartDataSetType } from "lib/interfaces/message";

export function getChartDatasetColor(index: number) {
    const colors = [
        '#8884d8',
        '#82ca9d',
        '#ffc658',
        '#ff7300',
        '#00C49F',
        '#0088FE',
        '#FFBB28'
    ];
    return colors[index % colors.length];
}

export function getChartDatasetFillColor(index: number) {
    const colors = [
        '#635DCC',
        '#5EBB81',
        '#FFB525',
        '#FF8F33',
        '#00F7C8',
        '#32A0FF',
        '#F4A700'
    ];
    return colors[index % colors.length];
}

export function getChartData(chartData: MessageChartDataType): ChartDataType[] {
    const data: ChartDataType[] = []

    chartData.labels.forEach((label: string, index: number) => {
        const dataPoint: ChartDataType = {
            name: label
        }

        chartData.datasets.forEach((dataset: MessagehartDataSetType) => {
            dataPoint[dataset.label] = dataset.data[index];
        });

        data.push(dataPoint);
    });

    return data;
}