import React from 'react';
import { Card as AntCard } from 'antd';
import styles from './Card.module.scss';
import { LinkButton } from 'components/ui/linkButton/LinkButton';

const { Meta } = AntCard;

type CardProps = {
    title: string;
    text: string;
    link_detail?: string;
    link_chat?: string;
    image: React.ReactElement;
    redirectToLocalhost?: string;
}

const Card = ({ title, text, link_detail, link_chat, image, redirectToLocalhost }: CardProps) => {
    return (
        <AntCard
            className={styles.card}
            cover={image}>
            <Meta
                title={title}
                description={text}
            />
            <div className={styles.card_actionWrapper}>
                {link_detail && <LinkButton value={'details'} link={link_detail} />}
                {link_chat && <LinkButton value={'open chat'} link={link_chat} redirectToLocalhost={redirectToLocalhost} />}
            </div>
        </AntCard>
    )
}

export { Card };
