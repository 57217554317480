import React from 'react';
import { Card as AntCard } from 'antd';
import styles from './CardDetail.module.scss';
import { LinkButton } from 'components/ui/linkButton/LinkButton';

const { Meta } = AntCard;

type CardProps = {
    title: string;
    content: React.ReactNode;
}

const CardDetail = ({ title, content }: CardProps) => {
    return (
        <AntCard
            className={styles.card}>
            <Meta
                title={title}
                description={content}
            />
        </AntCard>
    )
}

export { CardDetail };
