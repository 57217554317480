import { ChartType, ContentType } from 'lib/enums/message';
import styles from './BotMessage.module.scss';
import { Message, MessageChartDataType, MessageStructure, MessageTableContent } from 'lib/interfaces/message';
import { Table } from '../table/Table';
import LineChart from '../charts/LineChart';
import BarChart from '../charts/BarChart';
import AreaChart from '../charts/AreaChart';
import { Avatar, List } from 'antd';
import { RobotOutlined } from "@ant-design/icons";


const TextBubble = ({ message }: { message: MessageStructure }) => {
    if ('text' in message.content) {
        return (
            <div className={styles.botMessage_content}>
                {message.content.text}
            </div>
        )
    }

    return null;
}

const TableBubble = ({ message }: { message: MessageStructure }) => {
    if ('headers' in message.content && 'rows' in message.content) {
        return (
            <div className={styles.botMessage_content}>
                <Table tableData={message.content as MessageTableContent} />
            </div>
        )
    }

    return null;
}

const ChartBubble = ({ message }: { message: MessageStructure }) => {
    if ('chart_type' in message.content && 'data' in message.content) {
        if (message.content.chart_type === ChartType.LINE)
            return (
                <div className={styles.botMessage_chartContent}>
                    <LineChart chartData={message.content.data as MessageChartDataType} />
                </div>
            )
        if (message.content.chart_type === ChartType.GROUPED_BAR)
            return (
                <div className={styles.botMessage_chartContent}>
                    <BarChart chartData={message.content.data as MessageChartDataType} />
                </div>
            )
        if (message.content.chart_type === ChartType.STACK_AREA)
            return (
                <div className={styles.botMessage_chartContent}>
                    <AreaChart chartData={message.content.data as MessageChartDataType} />
                </div>
            )
    }

    return null;
}

type BotMessageProps = {
    message: Message
}

const BotMessage = ({ message }: BotMessageProps) => {
    console.log(message);
    return (
        <div
            className={styles.botMessage}>
            <List.Item.Meta
                avatar={<Avatar src={<RobotOutlined />} />}
                className={styles.botMessage_meta}
            />
            <div className={styles.botMessage_contentWrapper}>
                {
                    message.message.map((messageStructure: MessageStructure, index: number) => {
                        if (messageStructure.content_type === ContentType.TEXT) {
                            return <TextBubble message={messageStructure} key={index} />
                        }
                        if (messageStructure.content_type === ContentType.TABLE) {
                            return <TableBubble message={messageStructure} key={index} />
                        }
                        if (messageStructure.content_type === ContentType.CHART) {
                            return <ChartBubble message={messageStructure} key={index} />
                        }
                        return null
                    })
                }
            </div>
        </div>
    )

}

export default BotMessage;
