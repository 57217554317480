import { useAuthData } from 'hooks/useAuthData';
import { AuthState } from 'lib/interfaces/auth';
import React from 'react';
import { authInitialState } from 'services/auth';

export type AuthContext = AuthState & {
  storeData: (authData: Partial<AuthState>) => void,
  logout: () => void;
};

export const AppContext = React.createContext<AuthContext>({
  ...authInitialState,
  storeData: () => { },
  logout: () => { }
});

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { accessToken, isLogged, idToken, user_name, user_email, clientId, authError, storeData, logout } = useAuthData();

  return (
    <AppContext.Provider value={{
      accessToken,
      isLogged,
      idToken,
      user_name,
      user_email,
      clientId,
      authError,
      storeData,
      logout
    }}>
      {children}
    </AppContext.Provider>
  );
};

