import { Table as AntTable } from "antd";
import styles from './Table.module.scss';
import { ColumnType, DataSourceType } from "lib/interfaces/table";
import { MessageTableContent, MessageTableRow } from "lib/interfaces/message";


type TableProps = {
    tableData: MessageTableContent;
}

const Table = ({ tableData }: TableProps) => {
    const columns: ColumnType[] = [];
    const dataSource: DataSourceType[] = [];

    tableData.headers.forEach((header: string) => {
        columns.push({
            title: header,
            dataIndex: header.toLowerCase(),
            key: header.toLowerCase()
        });
    })

    tableData.rows.forEach((rowItems: MessageTableRow, index: number) => {
        const dataSourceItem: DataSourceType = {
            key: index.toString()
        };
        columns.forEach((column, index: number) => {
            dataSourceItem[column.dataIndex] = rowItems[index];
        })
        dataSource.push(dataSourceItem);
    })

    return <AntTable className={styles.table} dataSource={dataSource} columns={columns} pagination={false} />;
}


export { Table };
