import Spin from 'antd/lib/spin';
import styles from './LoadingDialog.module.scss';

type LoadingDialogProps = {
    value: string;
}

const LoadingDialog = ({ value }: LoadingDialogProps) => {
    return (
        <div className={styles.loadingDialog_wrapper}>
            <div className={styles.loadingDialog_card}>
                <Spin size="large" />
                <p>{value}</p>
            </div>
        </div>
    )
}

export { LoadingDialog };
