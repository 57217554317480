import { Layout } from 'antd';
import { MainMenu } from "./MainMenu";
import nessLogo from 'assets/ness_logo.png';
import styles from './ChatHeader.module.scss';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'lib/routes';

const { Header } = Layout;

const ChatHeader = () => {
    return (
        <Header className={styles.header}>
            <NavLink to={ROUTES.homepage}>
                <img src={nessLogo} className={styles.header_logo} alt="nessLogo" />
            </NavLink>
            <MainMenu />
        </Header>
    )

}

export { ChatHeader };
