import 'antd/dist/reset.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { PageLayout } from "./components/layout/PageLayout";
import { RequireAuth } from "./hoc/RequireAuth";
import { ChatPage } from "./pages/chat/ChatPage";
import { HomePage } from "./pages/HomePage";
import { ROUTES } from 'lib/routes';
import { ChatPageLayout } from 'components/layout/ChatPageLaylout';
import { LandingPage } from 'pages/LandingPage';
import Cognito from 'components/cognito/Cognito';
import { LandingPageLayout } from 'components/layout/LandingPageLayout';
import { CustomerServiceAICompanionDetailPage } from 'pages/detail/CustomerServiceAICompanion';
import { PredictiveMaintenanceSystemDetailPage } from 'pages/detail/PredictiveMaintenanceSystem copy';
import { IOTDeviceSentinelDetailPage } from 'pages/detail/IOTDeviceSentinel';
import { FinancialCreditScoringAssistantDetailPage } from 'pages/detail/FinancialCreditScoringAssistant';
import { IOTDeviceAsisstantChatPage } from 'pages/chat/IOTDeviceAsisstantChatPage';

function App() {
  return (
    <Routes>
      <Route path={ROUTES.landingPage} element={<LandingPageLayout><LandingPage /></ LandingPageLayout>} />
      <Route path={ROUTES.login} element={<Cognito />} />
      <Route path={ROUTES.homepage} element={<RequireAuth><PageLayout><HomePage /></PageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.chat.nessGenSales} element={<RequireAuth><ChatPageLayout><ChatPage /></ ChatPageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.chat.actimizeCustomerServiceAiCompanion} element={<RequireAuth><ChatPageLayout><ChatPage /></ ChatPageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.chat.financialServicesCreditScoringAssistant} element={<RequireAuth><ChatPageLayout><ChatPage /></ ChatPageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.chat.progressRailIncidentManagementSystem} element={<RequireAuth><ChatPageLayout><ChatPage /></ ChatPageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.chat.vix} element={<RequireAuth><ChatPageLayout><IOTDeviceAsisstantChatPage /></ ChatPageLayout></RequireAuth>} />

      <Route path={ROUTES.caseStudies.detail.actimizeCustomerServiceAiCompanion} element={<RequireAuth><PageLayout><CustomerServiceAICompanionDetailPage /></ PageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.detail.financialServicesCreditScoringAssistant} element={<RequireAuth><PageLayout><FinancialCreditScoringAssistantDetailPage /></ PageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.detail.progressRailIncidentManagementSystem} element={<RequireAuth><PageLayout><PredictiveMaintenanceSystemDetailPage /></ PageLayout></RequireAuth>} />
      <Route path={ROUTES.caseStudies.detail.vix} element={<RequireAuth><PageLayout><IOTDeviceSentinelDetailPage /></ PageLayout></RequireAuth>} />
    </Routes>

  );
}

export default App;
