import { AreaChart as RechartAreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessagehartDataSetType, MessageChartDataType } from "lib/interfaces/message";
import { getChartData, getChartDatasetColor, getChartDatasetFillColor } from 'lib/utils/chart';

type AreaChartProps = {
    chartData: MessageChartDataType;
}

const AreaChart = ({ chartData }: AreaChartProps) => {
    const data = getChartData(chartData);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RechartAreaChart
                width={550}
                height={300}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {chartData.datasets.map((dataset: MessagehartDataSetType, index: number) => {
                    const strokeColor: string = getChartDatasetColor(index);
                    const fillColor: string = getChartDatasetFillColor(index);
                    return (
                        <Area
                            type="monotone"
                            dataKey={dataset.label}
                            stackId="1"
                            stroke={strokeColor}
                            fill={fillColor}
                        />
                    )
                })}
            </RechartAreaChart>
        </ResponsiveContainer>
    );
}

export default AreaChart;