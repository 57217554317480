import { useLocation, Navigate } from 'react-router-dom';
import { useAuthContext } from "../hooks/useAuthContext";
import { ROUTES } from 'lib/routes';
import { isValidToken } from 'lib/utils/auth.utils';

interface Props {
  children: JSX.Element
}

const RequireAuth = ({ children }: Props) => {

  const location = useLocation();
  const { isLogged, idToken } = useAuthContext();


  if (!isLogged || !idToken || !isValidToken(idToken)) {
    return <Navigate to={ROUTES.login} state={{ from: location }} />
  }

  return children;
}

export { RequireAuth };
