import { Menu } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useAuthContext } from "hooks/useAuthContext";
import styles from './MainMenu.module.scss';
import { ROUTES } from "lib/routes";
import { useRevokeTokenMutation } from "services/auth";

const MainMenu = () => {
  const { isLogged, accessToken, logout } = useAuthContext();
  const revokeToken = useRevokeTokenMutation(logout);
  const navigate = useNavigate();
  const location = useLocation();
  const rightAlignedMenuItem = { marginLeft: 'auto' };

  /*
    TODO: replace redirect to localhost with this

    <Menu.SubMenu key="AdminSubMenu" title={'Case Studies'}>
        <Menu.Item key='/caseStudies/nessGenSales'>
          <NavLink to={ROUTES.caseStudies.nessGenSales}>
            <span>{'NessGen Sales Catalyst'}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='/caseStudies/actimizeCustomerServiceAiCompanion'>
          <NavLink to={ROUTES.caseStudies.actimizeCustomerServiceAiCompanion}>
            <span>{'Actimize Customer Service Ai Companion'}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='/caseStudies/financialServicesCreditScoringAssistant'>
          <NavLink to={ROUTES.caseStudies.financialServicesCreditScoringAssistant}>
            <span>{'Financial Services Credit Scoring Assistant'}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='/caseStudies/progressRailIncidentManagementSystem'>
          <NavLink to={ROUTES.caseStudies.progressRailIncidentManagementSystem}>
            <span>{'Progress Rail Incident Management System'}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='/caseStudies/wix'>
          <NavLink to={ROUTES.caseStudies.vix}>
            <span>{'VIX'}</span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>

  */
  return (
    <Menu className={styles.navigation} mode='horizontal' defaultSelectedKeys={['/']} selectedKeys={[location.pathname]}>
      {isLogged &&
        <Menu.SubMenu key="chatMenu" title={'Chat'}>
          <Menu.Item key='/caseStudies/nessGenSales'>
            <NavLink to={ROUTES.caseStudies.chat.nessGenSales}>
              <span>{'NessGen Sales Catalyst'}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/caseStudies/actimizeCustomerServiceAiCompanion'>
            <span onClick={() => window.open('http://localhost:8001/')}>{'Customer Service AI Companion'}</span>
          </Menu.Item>
          <Menu.Item key='/caseStudies/financialServicesCreditScoringAssistant'>
            <span onClick={() => window.open('http://localhost:8002/')}>{'Financial Credit Scoring Assistant'}</span>
          </Menu.Item>
          <Menu.Item key='/caseStudies/progressRailIncidentManagementSystem'>
            <span onClick={() => window.open('http://localhost:8003/')}>{'Predictive Maintenance System'}</span>
          </Menu.Item>
          <Menu.Item key='/caseStudies/wix'>
            <NavLink to={ROUTES.caseStudies.chat.vix}>
              <span>{'IOT Device Sentinel'}</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      }

      {isLogged &&
        <Menu.SubMenu key="detailsMenu" title={'Details'}>
          <Menu.Item key='/caseStudies/detail/actimizeCustomerServiceAiCompanion'>
            <NavLink to={ROUTES.caseStudies.detail.actimizeCustomerServiceAiCompanion}>
              <span>{'Customer Service AI Companion'}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/caseStudies/detail/financialServicesCreditScoringAssistant'>
            <NavLink to={ROUTES.caseStudies.detail.financialServicesCreditScoringAssistant}>
              <span>{'Financial Credit Scoring Assistant'}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/caseStudies/detail/progressRailIncidentManagementSystem'>
            <NavLink to={ROUTES.caseStudies.detail.progressRailIncidentManagementSystem}>
              <span>{'Predictive Maintenance System'}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/caseStudies/detail/vix'>
            <NavLink to={ROUTES.caseStudies.detail.vix}>
              <span>{'IOT Device Sentinel'}</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      }

      {!isLogged &&
        <Menu.Item key='/login'>
          <NavLink to={ROUTES.login}>
            <LoginOutlined />
            <span>{'Login'}</span>
          </NavLink>
        </Menu.Item>
      }
      {isLogged &&
        <Menu.Item
          key='logout'
          onClick={() => revokeToken.mutate({ accessToken: accessToken || undefined })}
        >
          <LogoutOutlined />
          <span>{'Logout'}</span>
        </Menu.Item>
      }
    </Menu>
  )
}


export { MainMenu };
