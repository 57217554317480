import { Title } from 'components/ui/title/Title';
import styles from './HomePage.module.scss';
import { Card } from 'components/ui/card/Card';
import { ROUTES } from 'lib/routes';
import poc_1 from 'assets/poc_1.png';
import poc_2 from 'assets/poc_2.png';
import poc_3 from 'assets/poc_3.png';
import poc_4 from 'assets/poc_4.png';
import poc_5 from 'assets/poc_5.png';
import poc_6 from 'assets/poc_6.png';
import genAI_logo from 'assets/genAI_logo.png';
import { CardMain } from 'components/ui/cardMain/CardMain';

const HomePage = () => {
  return (
    <div className={styles.homepage}>
      <div className={styles.homepage_header}>
        <img src={genAI_logo} alt={'genAI_logo'} />
      </div>
      <div className={styles.homepage_content_main}>
        <CardMain
          title={'Ness Sales Copilot'}
          text={'The GenAI virtual sales assistant for instant customer and strategy insights'}
          link_chat={ROUTES.caseStudies.chat.nessGenSales}
          image={<img src={poc_1} alt={'sales_image'} />}
        />
      </div>
      <div className={styles.homepage_header}>
        <Title value='Case Studies' />
        <p>
          Unlock the power of tomorrow with our GenAI studio, where innovation meets engineering excellence,
          offering limitless possibilities at your fingertips
        </p>
      </div>
      <div className={styles.homepage_content}>
        <Card
          title={'Customer Service AI Companion'}
          text={'The Ness GenAI customer experience platform'}
          redirectToLocalhost={'http://localhost:8001/'}
          link_detail={ROUTES.caseStudies.detail.actimizeCustomerServiceAiCompanion}
          link_chat={ROUTES.caseStudies.chat.actimizeCustomerServiceAiCompanion}
          image={<img src={poc_2} alt={'customer_image'} />}
        />
        <Card
          title={'Financial Credit Scoring Assistant'}
          text={'The Ness GenAI Predictive Analytics service'}
          redirectToLocalhost={'http://localhost:8002/'}
          link_detail={ROUTES.caseStudies.detail.financialServicesCreditScoringAssistant}
          link_chat={ROUTES.caseStudies.chat.financialServicesCreditScoringAssistant}
          image={<img src={poc_3} alt={'financial_image'} />}
        />
        <Card
          title={'Predictive Maintenance System'}
          text={'The Ness GenAI Predictive Maintenance and Incident management engine'}
          redirectToLocalhost={'http://localhost:8003/'}
          link_detail={ROUTES.caseStudies.detail.progressRailIncidentManagementSystem}
          link_chat={ROUTES.caseStudies.chat.progressRailIncidentManagementSystem}
          image={<img src={poc_4} alt={'predictive_image'} />}
        />
        <Card
          title={'IOT Device Sentinel'}
          text={'The Ness GenAI IOT Command Center'}
          link_detail={ROUTES.caseStudies.detail.vix}
          link_chat={ROUTES.caseStudies.chat.vix}
          image={<img src={poc_5} alt={'iot_image'} />}
        />
        <Card
          title={'Grader Assistant'}
          text={'The Ness GenAI Educational platform'}
          image={<img src={poc_6} alt={'grader_image'} />}
        />
      </div>
    </div>
  )
}

export { HomePage };
