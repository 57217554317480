import { Title } from 'components/ui/title/Title';
import styles from './detail.module.scss';
import { CardDetail } from 'components/ui/cardDetail/CardDetail';
import { XFilled } from '@ant-design/icons';

const IOTDeviceSentinelDetailPage = () => {
    const challenges =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Hard to find the right information
            </p>
            <p>
                <XFilled /> Difficult device monitoring process
            </p>
            <p>
                <XFilled /> Enable new engineers to get up-to speed swiftly
            </p>
        </div>
    const solution =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Instant access to all the information
            </p>
            <p>
                <XFilled /> Integrates a conversational interface on top of existing platforms
            </p>
            <p>
                <XFilled /> Advises on incident resolution
            </p>
            <p>
                <XFilled /> Returns the links and explains the solution
            </p>
            <p>
                <XFilled /> Understands the present, predicts the future, and converses about it
            </p>
        </div>
    const value =
        <div className={styles.detail_content}>
            <p>
                <XFilled /> Extracts the information and explains it instantly
            </p>
            <p>
                <XFilled /> Reduces incident resolution times
            </p>
            <p>
                <XFilled /> Easily expandable with additional classical ML and statistical calculation capabilities
            </p>
        </div>
    return (
        <div className={styles.detail}>
            <Title value='IOT Device Sentinel' />
            <CardDetail
                title={'Challenges'}
                content={challenges}
            />
            <CardDetail
                title={'Solution: NessGen IoT Management Assistant'}
                content={solution}
            />
            <CardDetail
                title={'Business Value'}
                content={value}
            />
        </div>
    )
}

export { IOTDeviceSentinelDetailPage };
