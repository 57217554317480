import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './ErrorDialog.module.scss';
import { Button } from 'components/ui/button/Button';


type LoadingDialogProps = {
    title: string;
    message: string;
    actionText: string;
    action: () => void;
}

const ErrorDialog = ({ title, message, actionText, action }: LoadingDialogProps) => {
    return (
        <div className={styles.errorDialog_wrapper}>
            <div className={styles.errorDialog_card}>
                <div className={styles.errorDialog_content}>
                    <div className={styles.errorDialog_icon}>
                        <ExclamationCircleOutlined />
                    </div>
                    <div className={styles.errorDialog_message}>
                        <h3>{title}</h3>
                        <p>{message}</p>
                    </div>
                </div>
                <div className={styles.errorDialog_action}>
                    <Button value={actionText} handleClick={action} />
                </div>
            </div>
        </div>
    )
}

export { ErrorDialog };
