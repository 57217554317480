import config from "../config";

export const ROUTES = {
    landingPage: config.PATH_ROOT,
    homepage: config.PATH_ROOT + '/home-page',
    login: config.PATH_ROOT + '/cognito',
    caseStudies: {
        detail: {
            nessGenSales: config.PATH_ROOT + '/case-studies/detail/ness-gen-sales',
            actimizeCustomerServiceAiCompanion: config.PATH_ROOT + '/case-studies/detail/customer-service-ai-companion',
            vix: config.PATH_ROOT + '/case-studies/detail/iot-device-sentinel',
            progressRailIncidentManagementSystem: config.PATH_ROOT + '/case-studies/detail/predictive-maintenance-system',
            financialServicesCreditScoringAssistant: config.PATH_ROOT + '/case-studies/detail/financial-credit-scoring-assistant',
        },
        chat: {
            nessGenSales: config.PATH_ROOT + '/case-studies/ness-gen-sales',
            actimizeCustomerServiceAiCompanion: config.PATH_ROOT + '/case-studies/customer-service-ai-companion',
            vix: config.PATH_ROOT + '/case-studies/iot-device-sentinel',
            progressRailIncidentManagementSystem: config.PATH_ROOT + '/case-studies/predictive-maintenance-system',
            financialServicesCreditScoringAssistant: config.PATH_ROOT + '/case-studies/financial-credit-scoring-assistant',
        }
    }
}