import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AuthError, BroadcastEventType, AuthState } from 'lib/interfaces/auth';

export function isValidToken(token: string): boolean {
    try {
        const decoded = jwtDecode(token);
        return decoded.exp ? decoded.exp * 1000 > Date.now() : false;
    } catch (e) {
        return false;
    }
}

export function tokenAboutToExpire(token: string): boolean {
    let decoded;

    try {
        decoded = jwtDecode(token);
    } catch (e) {
        return true;
    }

    if (!decoded.exp) {
        return true;
    }
    const twoMinutes = 2 * 60 * 1000;
    const tokenExpiry = decoded.exp * 1000;

    return (tokenExpiry - Date.now()) < twoMinutes;
}

/**
 * Post message to broadcast channel and close it afterwards
 * @param channelName BroadcastChannel name
 * @param eventType BroadcastEventType
 * @param data Partial<AuthState>
 */
export const broadcastAuthState = (channelName: string, eventType: BroadcastEventType, data: Partial<AuthState>) => {
    const writeChannel = new BroadcastChannel(channelName);
    writeChannel.postMessage({ eventType, data });
    writeChannel.close();
};

/**
 * pick properties to store in storage
 * simplify error object to avoid "postMessage couldn't be cloned" in broadcast channel
 * @param error @AxiosError
 * @returns
 */
export const convertAxiosError = (error: AxiosError): AuthError | undefined => {
    if (error) {
        return {
            message: error.message,
            code: error.code,
            status: error?.response?.status,
            url: error?.config?.url
        };
    }
};

export const lockedMutation = async (mutationHook: UseMutationResult<any, AxiosError, any>, data: any) => {
    let result;
    try {
        await navigator.locks.request(
            'refreshTokenLock',
            { ifAvailable: true },
            async (lock) => {
                if (!lock) {
                    // The lock was not granted
                    return;
                }

                result = await mutationHook.mutateAsync(data);
            }
        );
    } catch (error) {
        // lockedMutation error in lock wraper -> do nothing handled inside mutation
    }
    return result;
};
